@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
}

html,
body {
    font-size: 13px;
    font-family: 'Poppins', 'PS Regular', sans-serif !important;
    height: 100%;
    /* overflow-y: scroll; */
    /* -webkit-font-smoothing: antialiased; */

}

button {
    font-family: 'Poppins', 'PS Regular', sans-serif !important;
}

h1.fw-400 {
    font-weight: 400;
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

/* For mobile phones: */
[class*="col-"] {
    margin: inherit 15px;
    padding: 0 1rem !important;
}


.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

.col-5 {
    width: 41.66%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33%;
}

.col-8 {
    width: 66.66%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33%;
}

.col-11 {
    width: 91.66%;
}

.col-12 {
    width: 100%;
}

@media only screen and (max-width: 920px) {

    /* For mobile phones: */
    [class*="col-"] {
        margin-top: 15px;
        width: 100%;
    }

    .px-0 {
        padding: 0 .5rem !important;
    }

    .visible-xs {
        display: block !important;
    }

    .hidden-xs {
        display: none !important;
    }
}

.hidden-xs {
    display: block;
}

.visible-xs,
.d-none {
    display: none;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


.img-fluid {
    width: 100%;
    height: auto;
}

.text-center {
    text-align: center;
}

a {
    color: inherit;
    text-decoration: none;
}

.loader {
    z-index: 9999;
    position: fixed;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 8em;
    height: 8em;
    border: .7em solid rgba(0, 0, 0, 0.2);
    border-left: .7em solid #1e1e2d;
    border-radius: 5px;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader-admin {
    position: absolute;
    height: calc(100vh - 70px);
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    align-self: center;
    text-align: center;
    display: flex;
}

.loader-admin>div {

    width: 8em;
    height: 8em;
    border: .7em solid rgba(0, 0, 0, 0.2);
    border-left: .7em solid #1e1e2d;
    border-radius: 5px;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader-more {
    width: 3em;
    height: 3em;
    border: .4em solid rgba(0, 0, 0, 0.2);
    border-left: .4em solid #1e1e2d;
    border-radius: 5px;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
    margin: 0 auto;
}

.loader--hide {
    opacity: 0;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}









.ck-editor__editable {
    min-height: 150px !important;
    max-height: 400px !important;
}


.url:hover {
    color: #3699ff;
}



.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: #3699ff;
    border-color: #3699ff;
    color: #fff;
}

.pagination>li>a {
    border: 1px solid #3699ff;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #3699ff;
    border-color: #3699ff;
    outline: none;
}

.pagination>li>a,
.pagination>li>span {
    color: #3699ff;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 0px;
}



input[type=checkbox] {
    transform: scale(1.5);
}





[class*="col-"] {
    margin: inherit 15px;
    padding: 0 0.7rem !important;
}







/* Style the list */
ul.breadcrumb {
    /* padding: 10px 16px; */
    list-style: none;
    background-color: #eee;
    display: flex;
    justify-content: center;
}

/* Display list items side by side */
ul.breadcrumb li {
    display: inline;
    font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    /* content: "|\00a0"; */
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
    color: #fff;
    text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
    color: #fff;
    cursor: pointer;
}


.link_document:hover {
    color: #3699ff;
    font-weight: bold;
}



@keyframes fa-blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

.fa-blink {
    -webkit-animation: fa-blink 1.5s linear infinite;
    -moz-animation: fa-blink 1.5s linear infinite;
    -ms-animation: fa-blink 1.5s linear infinite;
    -o-animation: fa-blink 1.5s linear infinite;
    animation: fa-blink 1.5s linear infinite;
}
